@import './_variables.scss';

.ant-btn-text {
    font-size: 11px;
    font-weight: 600;
}

.ant-card-head-title {
    .ant-btn-link {
        color: $blue;
        font-size: 12px;
        text-decoration: underline;
    }
}

.ant-card-extra {
    .ant-btn-text {
        font-size: 11px;
        font-weight: 600;
        color: $blue;

        &.danger {
            color: $red;
        }
    }

    .ant-btn-primary {
        color: $white;
        background: $blue;
        border-color: $blue;
        font-size: 11px;
        font-weight: 600;
    }
}
