$header-height: 50px;
$sider-width: 300px;
$specialty-icon-size: 20px;

$white: #fff;
$off-white: #f5f7f8;
$black: #000;
$grey: #d6e3f2;
$grey2: #6c8095;
$grey3: #ffffff40;
$grey4: #6c8095;
$grey4-border: #d6e3f2;
$grey-label: #acbed0;
$lightblue: #f8fcff;
$blue: #0079c2;
$darkblue: #3d5681;
$red: #e74c3c;
$darkred: #8b0d00;
$yellow: #f1c40f;
$lightyellow: #f7edc7;
$darkyellow: #8e7200;
$green: #2ecc71;
$lightgreen: #b1efcb;
$purple: #b982d0;
$darkpurple: #a052c0;
$logoblue: #0d79bf;
$logoyellow: #f09920;
$logoteal: #00babf;
$orange: orange;
